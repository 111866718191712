
import moment from 'moment';
import { inject } from '@/inversify';
import { Component, Prop } from 'vue-property-decorator';

import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import ClipText from '@/modules/common/filters/clip-text.filter';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import type HotelsService from '@/modules/hotels/hotels.service';
import type PromotionsService from '../promotions.service';
import { PromotionGraphType } from '../models/promotions-settings.model';

interface TableData {
    value: number;
    valueString: number | string;
    hotelName: string;
    color: string;
    isMainHotel: boolean;
}

@Component({
    extends: DayTooltipTemplate,
})
export default class PromotionsGraphTooltip extends DayTooltipTemplate {
    @inject(KEY.PromotionsService)
    private promotionsService!: PromotionsService;

    @inject(KEY.HotelsService)
    private hotelsService!: HotelsService;

    @inject(KEY.DocumentFiltersService)
    private documentFilterService!: DocumentFiltersService;

    @Prop({
        type: Object,
        required: true,
        default: () => ({}),
    })
    private hiddenGraphs!: { [hotelId: number]: boolean };

    public get isActive() {
        return !!this.day;
    }

    public get isDealsMode() {
        return this.promotionsService.graphType === PromotionGraphType.DEALS;
    }

    public get date() {
        const { year, month } = this.documentFilterService;

        if (!this.day) return '---';

        const d = new Date(year, month, this.day);

        return moment(d).format('D MMMM YYYY');
    }

    public get isProgramMode() {
        return this.promotionsService.programView
            && !this.promotionsService.programView.includes('percentage');
    }

    public get programLabel() {
        const { programView, provider } = this.promotionsService;

        if (!programView || !provider) return '';

        return this.$tc(`promotions.program.${programView}`);
    }

    private get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    public get tableData() {
        const { day } = this;
        const { provider, data } = this.promotionsService;

        if (!data || !provider) return [];

        const { programView } = this.promotionsService;
        const { competitors } = this.documentFilterService;

        const hotelColors = this.hotelsService
            .getHotelsGraphColor(competitors.map(String));

        const shownHotels = [...competitors, this.currentHotelId];

        return data.hotels
            .filter(hotelId => !this.hiddenGraphs[hotelId] && shownHotels.includes(hotelId))
            .map(hotelId => {
                const programs = this.promotionsService
                    .getPrograms(day, provider, hotelId);

                const hotelName = this.hotelsService
                    .hotelNames[hotelId] || hotelId;

                const isMainHotel = this.currentHotelId === hotelId;

                const row = {
                    value: 0,
                    valueString: '-',
                    hotelName,
                    isMainHotel,
                    color: isMainHotel
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : hotelColors[hotelId],
                } as TableData;

                if (!programs) return row;

                const programList = Object.entries(programs);

                if (this.isDealsMode) {
                    const percentageProgram = programList
                        .find(([, program]) => program.percentage !== undefined)?.[1];

                    row.value = percentageProgram
                        ? percentageProgram.percentage
                        : 0;

                    row.valueString = percentageProgram
                        ? `${percentageProgram.percentage}%`
                        : '-';

                    return row;
                }

                row.value = programList
                    .filter(([programKey]) => !programView || programKey === programView)
                    .filter(([_, program]) => !('percentage' in program) && program.status)
                    .length;

                row.valueString = row.value || '-';

                return row;
            })
            .filter(row => !!row.value)
            .sort((a, b) => b.value - a.value);
    }

    cutString(value: string) {
        return ClipText(value, 26);
    }
}
